import React from 'react'
import { useFormik } from 'formik'
import './AddCouponForm.css'

export default function AddCouponForm(props) {
    const Form = () => {
        const formik = useFormik({
            initialValues: {
                coupon_id: '',
                name: '',
                discount_percentage: 0,
            },
            onSubmit: values => {
                new Promise((resolve, reject) => {

                    values.coupon_id = values.coupon_id.toUpperCase()

                    if (values.coupon_id === "" || values.name === "") {
                        reject("Non ci possono essere campi vuoti!")
                        return
                    }

                    if (isNaN(values.discount_percentage)) {
                        reject("Puoi inserire solo numeri in Discount Percentage!")
                        return
                    }


                    //request params 
                    var request = new XMLHttpRequest()
                    var params  = `id=${values.coupon_id}&name=${values.name}&discount_percentage=${values.discount_percentage}`
             
                    request.open('POST','/api/v1/coupons')
                    
                    //setting headers
                    request.setRequestHeader('Accept', 'application/json');
                    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                    request.setRequestHeader('Authorization', `Bearer ${props.jwtToken}`)

                    request.addEventListener('load', () => {
                        console.log("request readyState = ", request.readyState)
                        console.log("request status = ", request.status)
                        if(request.readyState === XMLHttpRequest.DONE && (request.status === 200 || request.status === 302)) { //success
                            resolve(`Coupon ${values.coupon_id} aggiunto con successo!`)
                        } else {
                            try {
                                let json = JSON.parse(request.responseText)
                                console.log(json)
                                reject(json)
                            } catch (e) {
                                reject("Errore parsing JSON")
                            }
                            
                        }
                    })

                    request.send(params)
                })
                    .then(onfulfilled => alert(onfulfilled))
                    .catch(onreject => alert(onreject.message))
            }
        });
    
        return (
             <form onSubmit={formik.handleSubmit}>
                 <div className='container d-flex p-2 justify-content-around'>
                     <div className='flex-row'>
                         <label htmlFor="coupon_id">ID Coupon</label>
                         <div>
                             <input
                                 id="coupon_id"
                                 name="coupon_id"
                                 type="text"
                                 onChange={formik.handleChange}
                                 value={formik.values.coupon_id}
                             />
                         </div>
                     </div>
                     <div className='flex-row'>
                         <label htmlFor="name">Coupon Name</label>
                         <div>
                             <input
                                 coupon_id="name"
                                 name="name"
                                 type="text"
                                 onChange={formik.handleChange}
                                 value={formik.values.name}
                             />
                         </div>
                     </div>
                     <div className='flex-row'>
                         <label htmlFor="discount_percentage">Discount Percentage</label>
                         <div>
                             <input
                                 coupon_id="discount_percentage"
                                 name="discount_percentage"
                                 type="text"
                                 onChange={formik.handleChange}
                                 value={formik.values.discount_percentage}
                             />
                         </div>
                     </div>
                     <button type="submit">Aggiungi il Coupon!</button>
                 </div>
             </form>
        )}

    return Form()
}
