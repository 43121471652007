import React from "react"

import AddCouponForm from '../components/AddCouponForm'

export default function AddCoupon(props) {

    if (!props.isAuthenticated) {
        props.history.push("/")
        return(
            <>
            </>
        )
    }

    return(
        <AddCouponForm {...props} />
    )
}
