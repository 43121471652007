import React from 'react';
import "./NotFound.css";

export default function NotFound () {
    return(
        <div className="NotFound">
            <h3>Pagina non trovata!</h3>
        </div>
    )
}
