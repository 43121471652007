import React from "react"
import { Route, Switch } from "react-router-dom"

//containers
import Home from "./containers/Home"
import Login from "./containers/Login"
import AddCoupon from './containers/AddCoupon'
import NotFound from './containers/NotFound'

//components
import AppliedRoute from './components/AppliedRoute'

//Need to put NotFound page

export default function Routes({appProps}) {
    const {isAuthenticated, setIsAuthenticated, jwtToken, setJwtToken} = {...appProps}
    
    return (
        <Switch>
            <AppliedRoute path="/" appProps={{
                isAuthenticated,
                jwtToken
                }} exact component={Home} 
                />

            <AppliedRoute path="/login" appProps={{
                isAuthenticated,
                setIsAuthenticated,
                setJwtToken,
                }} exact component={Login} 
                />

            <AppliedRoute path="/addcoupon" appProps={{
                isAuthenticated, 
                jwtToken
                }} exact component={AddCoupon} 
                />

            <Route component={NotFound} />
        </Switch>
    );
}
