import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom'

//Authentication
import Amplify from 'aws-amplify'
import config from './config'

Amplify.configure(
    { //initializing amplify
        Auth: {
            mandatorySignIn: true,
            region: config.cognito.REGION,
            userPoolId: config.cognito.USER_POOL_ID,
            identityPoolId: config.cognito.IDENTITY_POOL_ID,
            userPoolWebClientId: config.cognito.APP_CLIENT_ID
        }
    }
)

ReactDOM.render(
    <Router>
        <App/>,
    </Router>, 
    document.getElementById('root')
);
