import React from 'react'
import { Link } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap'

export default function MyNavbar(props) {

    return(
        <Navbar fluid collapseOnSelect>
            <Navbar.Header>
                <Navbar.Brand>
                    <Link to="/">Admin - Coupon</Link>
                </Navbar.Brand>
                <Navbar.Toggle />
            </Navbar.Header>
        
            <Navbar.Collapse>
                <Nav pullRight>
                {props.isAuthenticated? 
                <>
                    <LinkContainer to="/addcoupon">
                        <NavItem>Aggiungi coupon</NavItem>
                    </LinkContainer>
                    <NavItem onClick={props.handleLogout}>Logout</NavItem>
                </>
                : 
                <>
                    <LinkContainer to="/login">
                        <NavItem>Login</NavItem>
                    </LinkContainer>
                </>
                }
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
};
