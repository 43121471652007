import "./Login.css";

import React, { useState } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from '../components/LoaderButton';

import { Auth } from 'aws-amplify';

export default function Login(props) {

    //State
    const [username, setUsername]         = useState("")
    const [password, setPassword]   = useState("")
    const [isLoading, setIsLoading] = useState(false)

    function validateForm() {
        return username.length > 0 && password.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true)
        try {
            await Auth.signIn(username, password)
                .then(data => {
                    const jwtToken = data.getSignInUserSession()
                    .getIdToken()
                    .getJwtToken()

                    props.setJwtToken(jwtToken)
                    props.setIsAuthenticated(true)
                })
                .catch(onrejected => {
                    alert("Errore nelle credenziali")
                    props.setIsAuthenticated(false)
                })
        } catch (e) {
            setIsLoading(false)
            throw e
        }

        props.history.push("/")
    }
    
    return (
        <div className="Login">
            <form onSubmit={handleSubmit}>
            <FormGroup controlId="username" bsSize="large">
                <ControlLabel>Nome utente</ControlLabel>
                <FormControl
                    autoFocus
                    type="text"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                />
            </FormGroup>
            <FormGroup controlId="password" bsSize="large">
                <ControlLabel>Password</ControlLabel>
                <FormControl
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    type="password"
                />
            </FormGroup>
            <LoaderButton 
                block 
                bsSize="large" 
                isLoading={isLoading}
                disabled={!validateForm()} 
                type="submit"
            >
                Login
            </LoaderButton>
        </form>
        </div>
    );
}
