import React, { useState, useEffect } from "react"
import CouponsTable from '../components/CouponsTable'
import "./Home.css"

export default function Home(props) {
    const [coupons, setCoupons] = useState([])

    /*
     * Fa la chiamata alle API per ottenere tutti i coupon solo la prima volta che 
     * viene caricata la pagina
     */    
    useEffect(() => {
        if (!props.isAuthenticated) {
            return
        }

        new Promise((resolve, reject )=> {
            let request = new XMLHttpRequest()
     
            request.open('GET','/api/v1/coupons')
            
            //setting headers
            request.setRequestHeader('Accept', 'application/json') 
            request.setRequestHeader('Authorization', `Bearer ${props.jwtToken}`)

            request.addEventListener('load', () => {
                if(request.readyState === XMLHttpRequest.DONE && request.status === 200) { //success
                    resolve(JSON.parse(request.response))
                } else {
                    reject(request.responseText)
                }
            })
            request.send()
        })
            .then(onfulfilled => setCoupons(onfulfilled))
            .catch(onreject => alert(onreject))
    }, [props.uri, props.jwtToken, props.isAuthenticated])

    function renderCoupons() {
        return (
            <div className="coupons">
                <h1>TiNoleggio's coupons</h1>
            <CouponsTable coupons={ Array.isArray(coupons) && coupons !== []? coupons: []}/>
            </div>
        )
    }

    function renderLander() {
        return (
            <div className="lander">
                <h1>TiNoleggio Coupon</h1>
                <p>Check your coupons</p>
            </div>
        );
    }

    return (
        <div className="Home">
        {
            props.isAuthenticated
            ? renderCoupons()
            : renderLander()
        }        
        </div>
    );
}
