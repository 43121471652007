import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { Auth } from 'aws-amplify'

import "./App.css";
import Routes from "./Routes"
import MyNavbar from './components/MyNavbar'


function App(props) {
    //App states
    const [isAuthenticated, setIsAuthenticated]   = useState(false)
    const [isAuthenticating, setIsAuthenticating] = useState(true)
    const [jwtToken, setJwtToken]                 = useState("")

    useEffect(() => {
        onLoad()
    }, [])

    async function onLoad() {
        try {
            await Auth.currentSession()
                .then((data) => {
                    const jwtToken = data.getIdToken().getJwtToken()
                    setJwtToken(jwtToken)
                    setIsAuthenticated(true)
                })
        } catch(e) {
            if (e !== 'No current user') {
                alert(e)
                throw e
            }
        } finally {
            setIsAuthenticating(false)
        }
    }

    async function handleLogout() {
        await Auth.signOut()
            .then(() => {
                setIsAuthenticated(false)
                props.history.push("/login")
            })
    }
    
    return (
        !isAuthenticating &&
        <div className="App container">
            <MyNavbar 
                handleLogout={handleLogout} 
                isAuthenticated={isAuthenticated}
            />
            <Routes appProps={{ isAuthenticated, setIsAuthenticated, jwtToken, setJwtToken }} />
        </div>
    )
}
export default withRouter(App)
