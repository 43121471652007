import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'

export default function CouponsTable({ coupons }) {
    const columns = [
        {
            dataField: 'id',
            text: 'Coupon ID'
        }, {
            dataField: 'name',
            text: 'Name'
        }, {
            dataField: 'discount_percentage',
            text: 'Discount percentage'
        }, {
            dataField: 'enabled',
            text: 'Enabled'
        }, {
            dataField: 'created_time',
            text: 'Created time'
        }, /*{
            dataField: 'activation',
            text: 'Activation'
        }, {
            dataField: 'usage_count',
            text: 'Usage Count'
        }*/


    ];

    return (
        <>
            <BootstrapTable keyField='id' data={coupons} columns={ columns } />
        </>

    )
}
